












import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import { ModalFunctions } from '~/mixins/modals'
import { UserStore } from '@/store/user'

@Component
export default class ProducerMailingListSubscription extends Mixins(ModalFunctions) {
    @Prop({ type: Number }) producerId!: number

    @UserStore.Action('toggleProducerMailingListSubscription') toggleProducerMailingListSubscription: (
        { producerId, shouldReceiveEmail }: { producerId: IProducer['id']; shouldReceiveEmail: boolean }
    ) => void

    performAction(shouldReceiveEmail: boolean) {
        this.toggleProducerMailingListSubscription({ producerId: this.producerId, shouldReceiveEmail })
        this.closeModal()
    }
}
