var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal__container modal__container--overlay" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeModal,
              expression: "closeModal",
            },
          ],
          staticClass: "modalDialog",
        },
        [
          _c("icon", {
            staticClass: "modalcloseButton",
            attrs: { name: "close" },
            nativeOn: {
              click: function ($event) {
                return _vm.closeModal.apply(null, arguments)
              },
            },
          }),
          _c(
            "div",
            { staticClass: "modalContent modalContent--sideBySideButtons" },
            [
              _c("div", { staticClass: "modalContent__body" }, [
                _c("h3", { staticClass: "modal__title modal__title--sm" }, [
                  _vm._v(
                    "Do you want to receive emails when the producer uploads new beat?"
                  ),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--lg btn--primary",
                    on: {
                      click: function ($event) {
                        return _vm.performAction(true)
                      },
                    },
                  },
                  [_vm._v("YES")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--lg btn--white",
                    on: {
                      click: function ($event) {
                        return _vm.performAction(false)
                      },
                    },
                  },
                  [_vm._v("NO")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }